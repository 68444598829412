import { getCompassDirection, getDistance } from 'geolib';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { AdnginEndMobile0 } from '../../components/AdnginEndMobile0';
import { CorrectAnswers } from '../../components/CorrectAnswers';
import { FlagGrid } from '../../components/FlagGrid';
import { GuessList } from '../../components/GuessList';
import { NextRoundLink } from '../../components/NextRoundLink';
import { ShareButton } from '../../components/ShareButton';
import { StatsModal } from '../../components/StatsModal';
import {
  MAX_ATTEMPTS,
  TILE_COUNT,
  TILES_REVEALED_AT_START,
} from '../../constants';
import countryData from '../../data/countries';

import { useAllCountryNames } from '../../hooks/useAllCountryNames';

import { useConfettiThrower } from '../../hooks/useConfettiThrower';
import { useDailyCountryName } from '../../hooks/useDailyCountryName';
import { useDailySeed } from '../../hooks/useDailySeed';
import { useGuessHistory } from '../../hooks/useGuessHistory';
import { shuffleWithSeed } from '../../utils/shuffleWithSeed';
import { AnswerBox } from './components/AnswerBox';
import { Attempts } from './components/Attempts';
import { useAllCountryCodes } from '../../hooks/useAllCountryCodes';

const TILE_INDICES = Array.from({ length: TILE_COUNT }, (_, i) => i);

export function MainGameRoute() {
  const [score, setScore] = useState('DNF');
  const [flippedArray, setFlippedArray] = useState(Array(36).fill(false));
  const dayString = useDailySeed();
  const [randomOrder, setRandomOrder] = useState(() =>
    shuffleWithSeed(TILE_INDICES, dayString),
  );
  const [end, setEnd] = useState(false);
  const [guessHistory, addGuess] = useGuessHistory();
  const guesses = useMemo(
    () => guessHistory[dayString] || [],
    [guessHistory, dayString],
  );

  const trueCountry = useDailyCountryName();
  const allCountryNames = useAllCountryNames();
  const allCountryCodes = useAllCountryCodes();

  const revealRandomTile = useCallback(() => {
    const tilesToFlip = randomOrder.slice(0, 2);
    
    setRandomOrder(
      randomOrder.length > 2
        ? randomOrder.slice(2) 
        : shuffleWithSeed(TILE_INDICES, dayString),
    );
    setFlippedArray((currArray) => {
      const newFlipped = [...currArray];
      
      tilesToFlip.forEach(tile => {
        newFlipped[tile] = true;
      });
      return newFlipped;
    });
    return tilesToFlip;
  }, [setFlippedArray, randomOrder, dayString]);

  // const getRemainingTiles = useCallback(() => {
  //   const remainingTiles = [];
  //   const usedTiles = guesses.map((guess) => guess.tile);
  //   for (const i of TILE_INDICES) {
  //     if (!usedTiles.includes(i)) {
  //       remainingTiles.push(i);
  //     }
  //   }
  //   return remainingTiles;
  // }, [guesses]);

  const getRemainingTiles = useCallback(() => {
    const remainingTiles = [];
    const usedTiles = guesses.map((guess) => guess.tile);
    const flippedTiles = Array.from({ length: TILE_INDICES.length }, (_, index) => index).filter(tile => flippedArray[tile]); // Assume flippedArray contains flipped state of tiles
    const excludedTiles = [...usedTiles, ...flippedTiles];
    for (const i of TILE_INDICES) {
      if (!excludedTiles.includes(i)) {
        remainingTiles.push(i);
      }
    }
    return remainingTiles;
  }, [guesses]);


  const revealTiles = useCallback(() => {
    setFlippedArray((currFlipped) => {
      const newFlipped = [...currFlipped];

      // for (const guess of guesses) {
      //   newFlipped[guess.tile] = true;
      // }
      for (const guess of guesses) {
        const tile = guess.tile; // Assuming tile is an array of 4 elements
  
        // Iterate over each element in tile array
        for (const index of tile) {
          // Set corresponding index in newFlipped to true
          newFlipped[index] = true;
        }
      }

      return newFlipped;
    });
  }, [setFlippedArray, guesses]);

  const throwConfetti = useConfettiThrower();
 

  useEffect(() => {
    if (end) return;
    revealTiles();
    getRemainingTiles();
    const lastGuess = guesses[guesses.length - 1];
    if (guesses.length >= MAX_ATTEMPTS || lastGuess?.distance === 0) {
      setEnd(true);
      setFlippedArray(Array(36).fill(true));
      if (guesses[guesses.length - 1].distance === 0) {
        toast(`🎉 ${trueCountry} 🎉`, { autoClose: 3000 });
        throwConfetti();
        setScore(guesses.length);
      } else {
        toast(`🤔 ${trueCountry} 🤔`, { autoClose: 3000 });
        setScore('DNF');
      }
    }
  }, [
    guesses,
    trueCountry,
    getRemainingTiles,
    revealTiles,
    throwConfetti,
    end,
  ]);

  
  useEffect(() => {
    if (randomOrder.length < 36 || !TILES_REVEALED_AT_START) return;

    setFlippedArray((prev) => {
      const newFlippedArray = [...prev];
      for (let i = 0; i < TILES_REVEALED_AT_START; i++) {
        newFlippedArray[randomOrder[i]] = true;
      }
      return newFlippedArray;
    });

    setRandomOrder((randomOrder) => randomOrder.slice(0, 2));
  }, [setFlippedArray, setRandomOrder, randomOrder]);

  const onGuess = useCallback(
    (guess) => {
      if (guesses.findIndex((g) => g.name === guess) !== -1) {
        return toast(`Você já escolheu ${guess}`, { autoClose: 3000 });
      }
      const tileNum = revealRandomTile();
      const { ...guessGeo } = countryData[guess];
      const { ...answerGeo } = countryData[trueCountry];
      const code = countryData[guess.code]
      addGuess({
        name: guess,
        code: guessGeo.code,
        distance: getDistance(guessGeo, answerGeo),
        direction: getCompassDirection(guessGeo, answerGeo),
        tile: tileNum,
      });
    },
    [addGuess, revealRandomTile, trueCountry, guesses],
  );

  const countryInfo = useMemo(() => countryData[trueCountry], [trueCountry]);



  return (
    <>
      <FlagGrid
        end={end}
        countryInfo={countryInfo}
        flippedArray={flippedArray}
        trueCountry={trueCountry}
        
      ></FlagGrid>
      
      {end && (
        <>
          <CorrectAnswers answers={[trueCountry]} />
        </>
      )}
      <p className="mb-0 text-sm text-white">
        {TILES_REVEALED_AT_START > 0 || guesses?.length > 0 ? (
          <br />
        ) : (
          `Chute um time para começar!`
        )}
      </p>
      <AnswerBox 
          disabled={end} 
          countries={allCountryCodes} 
          onGuess={onGuess}
          
      />
      <Attempts score={score} attempts={guesses.length} max={MAX_ATTEMPTS} />
      <GuessList guesses={guesses} answers={countryInfo.code} />
{/* 
      {end && (
        <>
          <CorrectAnswers answers={[trueCountry]} />
          <NextRoundLink to="/bonus-round/1">
            Jogo bonus - Jogo dos 15
          </NextRoundLink>
        </>
      )} */}


      {/* <AdnginEndMobile0 /> */}
    </>
  );
}
