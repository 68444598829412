import Typography from '@mui/material/Typography';
import { getCompassDirection, getDistance } from 'geolib';
import { useState } from 'react';
import styled from 'styled-components';

import { MAX_ATTEMPTS } from '../constants';
import countryData from '../data/countries';
import { BaseModal } from './BaseModal';
import { FlagGrid } from './FlagGrid';
import { GuessList } from './GuessList';
import { Quiz } from '@mui/icons-material';

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const CenterDiv = styled.div<{ display: string }>`
  display: ${(props) => props.display};
  justify-content: center;
  
  
`;

const HelpIcon = styled(Quiz)`
  color: white;
  margin: 3px;
`;

export function HowToModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const exampleGuesses = ['Real Madrid', 'Paris Saint-Germain', 'Liverpool', 'Manchester City'];
  const exampleTarget = 'Manchester City';
  const { code: answerCode, ...answerGeo } = countryData[exampleTarget];
  const guesses = exampleGuesses.map((name) => {
    const { ...guessGeo } = countryData[name];
    const code2 = countryData[name]
    return {
      name: name,
      code: code2.code,
      distance: getDistance(guessGeo, answerGeo),
      direction: getCompassDirection(guessGeo, answerGeo),
      tile: 0,
    };
  });


  return (
    <div>
      <Button onClick={handleOpen}>
        <HelpIcon />
      </Button>
      <BaseModal open={open} onClose={handleClose} title="Como jogar!">
        <Typography id="modal-modal-paragraph" component="p">
          Acerte o escudo em {MAX_ATTEMPTS} tentativas ou menos!
        </Typography>
        <Typography id="modal-modal-paragraph" component="p">
          A cada tentativa, 2 blocos do escudo são revelados.
        </Typography>
        <br />
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Exemplo:
        </Typography>
        <CenterDiv display="flex">
          <FlagGrid
            end={false}
            flippedArray={[true, true, false, false, true, false, false, true, true, false, false, true, false, true, false, true, true, false, false, true, true, false, false, false, true, false, false, false, false, true, false, false, false, false, true, false]}
            countryInfo={{ code: answerCode, name:exampleTarget }}
            trueCountry={exampleTarget}
          ></FlagGrid>
        </CenterDiv>
        
        <CenterDiv display="grid" >
          <GuessList guesses={guesses.slice(0, -1)} answers={answerCode} />
        </CenterDiv>
        <br />
        <Typography id="modal-modal-paragraph" component="p">
          A dicas mostram se o time é do mesmo país da tentativa e também a distância entre a tentativa e a resposta correta.
        </Typography>
        <Typography id="modal-modal-paragraph" component="p">
          A resposta certa do exemplo é:
        </Typography>
        <br />
         <div >
          <CenterDiv display="grid">
              <GuessList guesses={guesses.slice(-1)} answers={answerCode} />
            </CenterDiv>
         </div>
          
        
       
        <br />
        <Typography
          id="modal-modal-paragraph"
          component="p"
        >
          <a>
          Todos os logotipos e marcas são propriedade de seus respectivos proprietários e são usados ​​apenas para fins de identificação.
          </a>
        </Typography>
        <br />
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Sugestões, erros?
        </Typography>
        <Typography
          id="modal-modal-paragraph"
          component="p"
          sx={{ textDecoration: 'underline' }}
        >
          <a
            href="mailto:lsanchessalvador@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            Envie seu comentário!
          </a>
        </Typography>
        <br />
        <Typography
          id="modal-modal-paragraph"
          component="p"
          sx={{ textDecoration: 'underline' }}
        >
          <a href="/privacy-policy/" target="_blank" rel="noreferrer">
            Política de privacidade
          </a>
        </Typography>
      </BaseModal>
    </div>
  );
}
