import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Button from '@mui/material/Button';
import { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { HowToModal } from './components/HowToModal';
import { StatsModal } from './components/StatsModal';
import { Title, TitleBar, TitleBarDiv } from './components/Title';
import { getDayString } from './hooks/useDailySeed';
import { useMainGameCompleted } from './hooks/useMainGameCompleted';
import { MainGameRoute } from './routes/MainGameRoute/MainGameRoute';
import { SettingsRoute } from './routes/SettingsRoute';
import countryData from './data/countries';
import { getCompassDirection, getDistance } from 'geolib';
import { FlagGrid } from './components/FlagGrid';
import { GuessList } from './components/GuessList';
import { Typography } from '@mui/material';
import { NextRoundLink } from './components/NextRoundLink';


const CentreWrapper = styled.div`
  margin: 0;
  overflow: auto;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    font-size: 0.8em;
  }
`;

const AdContainer = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 10px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const GameButton = styled(Button)`
  span {
    font-weight: bold;
    text-transform: none;
  }
`;

const Container = styled.div`
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    padding: 15px 15px;
`
const Footer = styled.div`
    display:block;
    width: 100%
    background-color: #262334;
    color:#ffffff;
    
`

const FooterBottom = styled.div`
    display: flex;
    justify-content: center;
    padding: 20px 0 20px;
    background-color: #262334;
    @media (max-width: 479px){
      flex-direction: column;
      font-size:13px;
      align-items: center;
    }
`
const FooterCopir = styled.div`
  @media (max-width: 479px){
      margin-bottom: 10px;
      font-size:13px;
  }
  
`
const FooterLinks = styled.ul`
    line-height: 12px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
`
const FooterLi = styled.li`
    border-left: 1px solid #75798d;
    padding-left: 10px;
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    @media (max-width: 479px) {
      &:first-child {
          border-left: 0;
          padding-left: 0;
          margin-left: 0;
      }
  }
`

const SobreContainer = styled.div`
  display: flex;
  justify-content: center;
  width:100%;
  padding: 0 15px;
  color: #cac8d5;
  margin-top:30px;
  background-color: #262334;
  h1{
    color: #fff;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 5px;
    text-transform:uppercase;
  }
  span{
    color: #cac8d5;
    font-size: 16px;
    font-weight: 400;
    display: block;
    line-height: 30px;
}
  }
`;
const ComoJogarContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #cac8d5;
  width:100%;
  max-width: 100%
  margin: 0 auto;
  padding: 15px 15px;
  h1{
    color: #fff;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 5px;
    text-transform:uppercase;
  }
  h2{
    font-family: 'Mitr', sans-serif;
    color: #fff;
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 25px;
  }
  h3{
    font-family: 'Mitr', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
  }
  span{
    color: #cac8d5;
    font-size: 16px;
    font-weight: 400;
    display: block;
    line-height: 30px;
}
  }
`;
const CenterDiv = styled.div<{ display: string }>`
  display: ${(props) => props.display};
  justify-content: center;
  margin-top:30px;
  margin-bottom:30px;
`;

/**** MJD - ADDED THIS TO SUPPORT BONUS ROUND FIX *****/

const startDate = getDayString();

window &&
  window.setInterval(() => {
    const currentDate = getDayString();
    if (startDate !== currentDate) {
      window.dispatchEvent(new CustomEvent('date-changed'));
    }
  }, 1000);

const refreshPage = () => {
  window && (window.location.href = '/');
};

// const LazyFirstBonusRoundRoute = lazy(() =>
//   import(
//     /* webpackChunkName: "FirstBonusRoundRoute", webpackPreload: true */ './routes/BonusGameRoute/BonusGame'
//   ).then((module) => ({
//     default: module.BonusGame,
//   })),
// );

export function App() {
  const mainGameCompleted = useMainGameCompleted();
  /**** MJD - ADDED THIS TO SUPPORT BONUS ROUND FIX *****/
  useEffect(() => {
    window.addEventListener('date-changed', refreshPage);
    return () => {
      window.removeEventListener('date-changed', refreshPage);
    };
  }, []);
  /**** MJD - ADDED THIS TO SUPPORT BONUS ROUND FIX END *****/
  const exampleGuesses = ['Real Madrid', 'Paris Saint-Germain', 'Liverpool', 'Manchester City'];
  const exampleTarget = 'Manchester City';
  const { code: answerCode, ...answerGeo } = countryData[exampleTarget];
  const guesses = exampleGuesses.map((name) => {
    const { ...guessGeo } = countryData[name];
    const code2 = countryData[name]
    return {
      name: name,
      code: code2.code,
      distance: getDistance(guessGeo, answerGeo),
      direction: getCompassDirection(guessGeo, answerGeo),
      tile: 0,
    };
  });
  const currentYear: number = new Date().getFullYear();
  return (
    <div className="App">
      <ToastContainer
        hideProgressBar
        position="top-center"
        transition={Flip}
        autoClose={false}
      />
      <CentreWrapper className="App-Center">
        <TitleBar className='App-Bar'>
          <TitleBarDiv />
          <TitleBarDiv justify="flex-end">
            <HowToModal />
          </TitleBarDiv>
          <Title className='App-Title'>
            QUE ESCUDO É ?
          </Title>
          <TitleBarDiv className='App-Icon'>
          {mainGameCompleted ? (
                <StatsModal end={true}></StatsModal>  
                
              ) : (
                <StatsModal end={false}></StatsModal>
              )
          } 
          </TitleBarDiv>
        </TitleBar>

        <Suspense fallback="loading next bonus round…">
          <Switch>
            <Route exact path="/">
              <MainGameRoute />
            </Route>
            {/* <Route exact path="/bonus-round/1">
              {mainGameCompleted ? (
                <LazyFirstBonusRoundRoute />
              ) : (
                <Redirect to="/" />
              )}
            </Route> */}
            {/* <Route exact path="/settings">
              <SettingsRoute />
            </Route>
            <Route>
              <NextRoundLink to="/">Home</NextRoundLink>
            </Route> */}
            
          </Switch>
        </Suspense>
         
          
          <SobreContainer>
            <Container>
            <div className='section-infos'>
            <h1>Que escudo é?</h1>
            <span>Que escudo é um jogo que testará seus conhecimentos de futebol e geografia. As regras são muito simples: você tem que adivinhar de um time aleatório e tem 6 palpites para fazer isso. Cada vez que você falha, 2 partes do escudo oculto são desbloqueados. Além do mais, você receberá sempre dicas geográficas sobre a localização do país do time oculto.</span>
            </div>
            </Container>
          </SobreContainer>
          <ComoJogarContainer>
            <Container>
              <div>
                <h2>COMO JOGAR?</h2>
                <h3>Escolha um time aleatório	para começar</h3>
                <span>Clique no campo 'Adivinhe o escudo!' e escolha qualquer time da lista, em seguida utilize as dicas geográficas como direção e distância para as próximas tentativas</span>
                <CenterDiv display="flex">
                  <FlagGrid
                  end={false}
                  flippedArray={[false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, true, false, false, true, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false]}
                  countryInfo={{ code: answerCode, name:exampleTarget }}
                  trueCountry={exampleTarget}
                ></FlagGrid>
                </CenterDiv>
                
                <h2>DICAS</h2>
                <span>O número de quilômetros mostrará a distância do país do time. A seta indicará a direção em que procurar o país cuja o escudo do time está oculto.</span>
                <CenterDiv display="grid" >
                  <GuessList guesses={guesses.slice(0, -1)} answers={answerCode} />
                </CenterDiv>
                <span>
                   A resposta certa do exemplo é:
                </span>
                <div >
                  <CenterDiv display="grid">
                      <GuessList guesses={guesses.slice(-1)} answers={answerCode} />
                  </CenterDiv>
                </div>
              </div>
            </Container>
          
          </ComoJogarContainer>
          
      </CentreWrapper>
      <Footer>
          <FooterBottom>
            <FooterCopir>queescudo.xyz © {currentYear} Todos os direitos reservados.</FooterCopir>
            <FooterLinks>
              <FooterLi>
                <a href="mailto:lsanchessalvador@gmail.com">Feedback</a>
              </FooterLi>
              <FooterLi>
                <a href="/privacy-policy">Política de Privacidade</a>
              </FooterLi>
              {/* <FooterLi>
                <a href="https://www.profitablegatecpm.com/ceqe53vmu?key=ef57a6b604fc7d15d628926b9e0f5f19">Apoie o jogo!</a>
              </FooterLi> */}
            </FooterLinks>
          </FooterBottom>
      </Footer>
    </div>
  );
}

const AdWrapperWrapper = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
`;

const AdWrapper = styled('div')`
  position: sticky;
  padding: 20px 40px 20px 20px;
  pointer-events: all;
`;
