import { useMemo } from "react";

import countryData from "../data/countries";



export function useAllCountryCodes() {
  // return useMemo(() => Object.values(countryData), []);
  
  return useMemo(() => {
    return Object.entries(countryData).map(([country, data]) => ({
      name: country,
      code: data.code,
    }));
  }, []);
}
