import dayjs from 'dayjs';
import seedrandom from 'seedrandom';
import countryData from '../data/countries';


interface Country {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
}

const noRepeatStartDate = dayjs('2024-03-27');
const excludePrimaryDate = dayjs('2024-03-27');
const excludeCountry = "";
type DateTime = dayjs.Dayjs;

export function getCountry(dayString: string) {
  const currentDayDate = dayjs(dayString);
  let pickingDate = dayjs('2024-03-27');
  let pickedCountry: Country | null = null;
  const lastPickDates: Record<string, DateTime> = {};

  let countrySelection = Object.entries(countryData).map((entry) => ({
    code: entry[1].code.toUpperCase(),
    latitude: entry[1].latitude,
    longitude: entry[1].longitude,
    name: entry[0],
  }));

  do {

    const pickingDateString = pickingDate.format('YYYY-MM-DD');

    if (pickingDate >= excludePrimaryDate) {
      countrySelection = countrySelection.filter((c: Country) => {
        return excludeCountry.indexOf(c.name) === -1;
      });
    }
    let countryIndex = Math.floor(
      seedrandom.alea(pickingDateString)() * countrySelection.length,
    );
    pickedCountry = countrySelection[countryIndex];

    if (pickingDate >= noRepeatStartDate) {
      while (isARepeat(pickedCountry, lastPickDates, pickingDate)) {
        countryIndex = (countryIndex + 1) % countrySelection.length;
        pickedCountry = countrySelection[countryIndex];
      }
    }

    lastPickDates[pickedCountry.name] = pickingDate;
    pickingDate = pickingDate.add(1, 'day');
  } while (pickingDate <= currentDayDate);

  return pickedCountry;
}


function isARepeat(
  pickedCountry: Country | null,
  lastPickDates: Record<string, DateTime>,
  pickingDate: DateTime,
) {
  if (pickedCountry == null || lastPickDates[pickedCountry.name] == null) {
    return false;
  }
  const daysSinceLastPick = pickingDate.diff(
    lastPickDates[pickedCountry.name],
    'day',
  );


  return daysSinceLastPick < 300;
}

export function getTodaysCountry(dayString: string): Country {
  return getCountry(dayString);
}

//código antigo
// import dayjs from 'dayjs';
// import seedrandom from 'seedrandom';
// import countryData from '../data/countries';


// interface Country {
//   code: string;
//   latitude: number;
//   longitude: number;
//   name: string;
// }

// const noRepeatStartDate = dayjs('2024-03-27');
// const excludePrimaryDate = dayjs('2024-03-27');
// const excludeCountry = "";
// type DateTime = dayjs.Dayjs;

// export function getCountry(dayString: string) {
//   const currentDayDate = dayjs(dayString);
//   let pickingDate = dayjs('2024-03-27');
//   let pickedCountry: Country | null = null;
//   const lastPickDates: Record<string, DateTime> = {};

//   let countrySelection = Object.entries(countryData).map((entry) => ({
//     code: entry[1].code.toUpperCase(),
//     latitude: entry[1].latitude,
//     longitude: entry[1].longitude,
//     name: entry[0],
//   }));

//   do {

//     const pickingDateString = pickingDate.format('YYYY-MM-DD');

//     if (pickingDate >= excludePrimaryDate) {
//       countrySelection = countrySelection.filter((c: Country) => {
//         return excludeCountry.indexOf(c.code) === -1;
//       });
//     }
//     let countryIndex = Math.floor(
//       seedrandom.alea(pickingDateString)() * countrySelection.length,
//     );
//     pickedCountry = countrySelection[countryIndex];

//     if (pickingDate >= noRepeatStartDate) {
//       while (isARepeat(pickedCountry, lastPickDates, pickingDate)) {
//         countryIndex = (countryIndex + 1) % countrySelection.length;
//         pickedCountry = countrySelection[countryIndex];
//       }
//     }

//     lastPickDates[pickedCountry.name] = pickingDate;
//     pickingDate = pickingDate.add(1, 'day');
//   } while (pickingDate <= currentDayDate);

//   return pickedCountry;
// }


// function isARepeat(
//   pickedCountry: Country | null,
//   lastPickDates: Record<string, DateTime>,
//   pickingDate: DateTime,
// ) {
//   if (pickedCountry == null || lastPickDates[pickedCountry.code] == null) {
//     return false;
//   }
//   const daysSinceLastPick = pickingDate.diff(
//     lastPickDates[pickedCountry.code],
//     'day',
//   );


//   return daysSinceLastPick < 100;
// }

// export function getTodaysCountry(dayString: string): Country {
//   return getCountry(dayString);
// }
