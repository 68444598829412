import styled from 'styled-components';

export const TitleBarDiv = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify = 'center' }) => justify};
`;

export const TitleBar = styled.div`
  display: grid;
  max-width: 800px;
  grid-template-columns: 30px 30px 1fr 30px 30px;
  grid-template-rows: auto 1fr;
  grid-gap: 0.6rem;
  @media screen and (max-width: 640px) {
    grid-template-columns: 10px 30px 1fr 30px 10px;
  }
`;

export const Title = styled.div`
  display: block;
  font-size: 3rem;
  color: #59C174;
  @media screen and (max-width: 640px) {
    font-size: 1.7rem;
  }
`;
