import styled from 'styled-components';

export const Attempts = styled(({ attempts, max, ...props }) => (
  <div {...props}>
    TENTATIVAS:{' '}
    <span>
      {attempts}/{max}
    </span>
  </div>
))`
  font-family: 'Mitr', sans-serif;
  display: block;
  font-size: 1.5em;
  margin-bottom: 1rem;
  font-weight:400;
  span {
    font-weight: 400;
  }
  color: #ffffff;
`;
