import Poll from '@mui/icons-material/Leaderboard';
import { List, ListItem } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

import { useStats } from '../hooks/useStats';
import { BaseModal } from './BaseModal';



const StatNumber = styled.div`
  font-weight: bold;
  font-size: 2em;
  text-align: center;

`;

const StatText = styled.div`
  text-align: center;

  
`;

const StyledTile = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 640px) {
    font-size: 0.8em;
  }
}
`;
const StatsTile = ({ stat, text }: { stat: number; text: string }) => (
  <StyledTile>
    <StatNumber>{stat}</StatNumber>
    <StatText>{text}</StatText>
  </StyledTile>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(3.8rem, auto));
  grid-template-rows: auto 1fr;
  
`;

const StatsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const DistBar = styled.div<{ count: number; maxDistribution: number }>`
  flex: 0 1
    ${(props) => Math.round((props.count / props.maxDistribution) * 100)}%;
  background-color: #59C174;
  padding: 2px 5px;
  border-radius: 3px;
  margin-left: 0.5rem;
`;

const LeaderboardIconStyled = styled(Poll)`
  color: white;
  margin: 3px;
`;

const Type = styled(Typography)`
  margin-top: 5px !important;
`;


const Next = styled(Typography)`
  display:flex;
  justify-content:center;
  align-items:center;
  font-size: 3rem;
  font-weight: 300;
  p{
    margin-bottom:0;
  } 
  b{
    display: block;
    font-family: 'Mitr', sans-serif;
    font-weight: 600;
    font-size: 2em;
    
  }
`;

export function StatsModal({ end }: { end: any }) {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  
  useEffect(() => {setTimeout(() => setOpen(end), 1000)}, [end]);
  const { played, winRatio, currentStreak, maxStreak, guessDistribution} =
    useStats();
  const maxDistribution = Math.max(...guessDistribution);

  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      const timeDifference = tomorrow.getTime() - now.getTime();

      if (timeDifference > 0) {
        const hours = String(Math.floor((timeDifference / (1000 * 60 * 60)) % 24)).padStart(2, '0');
        const minutes = String(Math.floor((timeDifference / 1000 / 60) % 60)).padStart(2, '0');
        const seconds = String(Math.floor((timeDifference / 1000) % 60)).padStart(2, '0');
        setTimeRemaining(`${hours}:${minutes}:${seconds}`);
      } else {
        // If time has passed, reset the timer or perform any other action
        setTimeRemaining('00:00:00');
      }
    };

    const timer = setInterval(calculateTimeRemaining, 1000);
  // Clear the interval on component unmount to prevent memory leaks
  return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <StatsButton onClick={handleOpen}>
        <LeaderboardIconStyled />
      </StatsButton>
      <BaseModal open={open} onClose={handleClose} title="Estatísticas">
        <Grid>
          <StatsTile stat={played} text="Jogos" />
          <StatsTile stat={Math.round(winRatio * 100)} text="Vitórias %" />
          <StatsTile stat={currentStreak} text="Sequência" />
          <StatsTile stat={maxStreak} text="Sequência Máx." />
        </Grid>
        <Type id="modal-modal-title" variant="h6">
        </Type>
        <List>
          {guessDistribution.map((count, index) => (
            <ListItem sx={{ paddingBottom: 0 }} key={index}>
              <div>{index + 1}</div>
              <DistBar count={count} maxDistribution={maxDistribution}>
                {count}
              </DistBar>
            </ListItem>
          ))}
        </List>
        <Type id="modal-modal-description" sx={{ mt: 2 }}>
        {end && (
        <>
          <Next> 
            <div>
              <p>Próximo escudo em: </p>
              <b>{timeRemaining}</b>
            </div>
          </Next>
         
        </>
        )}
          {/* <Button
            variant="contained"
            onClick={() => {
              window.open('https://crisisrelief.un.org/t/ukraine');
            }}
          >
            🇺🇦 Donate to Ukraine ❤️
          </Button> */}
        </Type>
        
        
        
       
      </BaseModal>
    </div>
  );
}
