import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: #312B2D;
  color: #59C174;
  text-align:center;
  border-radius: 1em;
`;

const StyledBox = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  max-width: 640px;
  max-height: 70vh;
  background-color: #312B2D;
  border: 2px solid #59C174;
  border-radius: 1em;
  box-shadow: 24rem;
  width: 90%;
  outline-style: none;
  box-sizing: border-box;
  line-height: 1.5em;
  color: #ffffff;
  h2{
    font-size: 2rem;
    font-family: 'Mitr', sans-serif;
    font-weight: bold;
    color:#59C174;
  }
`;

const StyledModal = styled(Modal)`
  
`;

const InnerContainer = styled.div`
  padding: 1em;
  justify-content: flex-start;
  overflow: auto;

`;

export const BaseModal = ({
  children,
  open,
  onClose,
  title,
}: {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
}) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 10000 }}
    >
      <StyledBox>
        <Header className='modal-title' >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {title}
          </Typography>
        </Header>
        <InnerContainer>{children}</InnerContainer>
      </StyledBox>
    </StyledModal>
  );
};

export default BaseModal;
