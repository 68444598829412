import { useMemo } from 'react';

import { getTodaysCountry } from '../utils/countryDateUtils';

export function useDailyCountryName(): string {
  return useMemo(() => {
    const todaysCountry = getTodaysCountry(new Date().toISOString());

    return todaysCountry.name;
  }, []);
}
