import { useMemo } from "react";

import countryData from "../data/countries";



export function useAllCountryNames() {
  return useMemo(() => Object.keys(countryData), []);

  // return useMemo(() => {
  //   return Object.keys(countryData).map((key) => ({
  //     key,
  //     code: countryData[key].code,
  //   }));
  // }, []);
}
