import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const DELAY_TIME = 0.5;
const FLAG_WIDTH = 139;
const FLAG_SCALE = 1;
// const FLAG_SCALE = FLAG_WIDTH / 181;

const Grid = styled.div<{ end?: boolean }>`
  transition: 1s;
  transition-delay: ${DELAY_TIME}s;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: ${(props) => (props.end ? '0px' : '2px')};
  z-index: ${(props) => (props.end ? 2 : 1)};
  width: fit-content;
`;

const TileFront = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  background: #94a3b8;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  border-radius: 0.1rem;
`;

const TileBack = styled.div`
  transition: 1s;
  transition-delay: ${DELAY_TIME}s;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: #ffffff;
  backface-visibility: hidden;
  position: absolute;
  transform: rotateY(180deg);
  top: 0;
  overflow: hidden;
`;

const Tile = styled.div<{
  height: number;
  rotate: string;
}>`
  transition: transform 1s;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  padding: ${(props) =>
    `${props.height ? `${props.height / 2}px` : '2rem'} ${Math.floor(
      FLAG_WIDTH / 6,
    )}px`};
  position: relative;
  transform: ${(props) =>
    props.rotate === 'true' ? 'rotateY(180deg)' : 'rotateY(0deg)'};
`;

const FlagImage = styled.img<{
  flag: string;
  left: number;
  top: number;
  height: number;
}>`
  content: url(${(props) => props.flag});
  position: relative;
  width: ${FLAG_WIDTH}px;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  max-width: unset;
  height: ${(props) => `${props.height}px`};  
`;

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${FLAG_WIDTH}px;
  min-height: ${(FLAG_WIDTH * 2) / 3}px; // 3:2 aspect ratio
`;

const MainContainer = styled.div`
  padding: 1rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  min-height: 200px;
  border-radius: 0.5rem;
`;

export function FlagGrid({
  end,
  countryInfo,
  flippedArray,
  trueCountry,
}: {
  end: boolean;
  countryInfo: { code: string, name: string };
  flippedArray: boolean[];
  trueCountry:string;
}) {
  const [flagLoad, setFlagLoad] = useState(false);
  const [scaledFlagHeight, setScaledFlagHeight] = useState(0);
  const flagImg = useMemo(() => {
    const img = new Image();
    img.onload = () => setFlagLoad(true);
    img.src=require(`../../public/images/times/${trueCountry}.png`);
   
    return img;
  }, [countryInfo]);

  useEffect(() => {
    setScaledFlagHeight(Math.floor(FLAG_SCALE * flagImg.height));
  }, [flagImg, flagLoad]);

  return (
    <MainContainer>
      <FlagContainer>
            {flagLoad ? (
              <Grid end={end}>
                {flippedArray.map((flipped, n) => (
                  <Tile
                    key={n}
                    rotate={flipped && flagLoad ? 'true' : 'false'}
                    height={scaledFlagHeight / 12}
                  >
                    <TileFront></TileFront>
                    <TileBack>
                      <FlagImage
                        flag={flagImg.src}
                        left={-Math.floor((n % 3) * (FLAG_WIDTH / 3))}
                        top={-((Math.floor(n / 3) * scaledFlagHeight) / 12)}
                        height={scaledFlagHeight}
                      ></FlagImage>
                    </TileBack>
                  </Tile>
                ))}
              </Grid>
            ) : null}
          </FlagContainer>
    </MainContainer>
   
  );
}
