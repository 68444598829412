/* prettier-ignore */


const countryData: {
  [key: string]: {
    code: string;
    latitude: number;
    longitude: number;
  };
} = {
  "AFC Bournemouth":{
    code: "gb-eng",
    latitude: 50.735278,
    longitude: -1.838333,
  },
  "Arsenal":{
    code: "gb-eng",
    latitude: 51.55505,
    longitude: -.108046,
  },
  "Aston Villa":{
    code: "gb-eng",
    latitude: 52.509192,
    longitude: -1.884828,
  },
  "Brentford":{
    code: "gb-eng",
    latitude: 51.4907295,
    longitude: -.2891696,
  },
  "Brighton & Hove Albion":{
    code: "gb-eng",
    latitude: 50.861582,
    longitude: -.08366,
  },
  "Burnley":{
    code: "gb-eng",
    latitude: 53.789167,
    longitude: -2.23027,
  },
  "Chelsea":{
    code: "gb-eng",
    latitude: 51.481579,
    longitude: -.191094,
  },
  "Crystal Palace":{
    code: "gb-eng",
    latitude: 51.398333,
    longitude: -.085556,
  },
  "Everton":{
    code: "gb-eng",
    latitude: 53.438801,
    longitude: -2.966328,
  },
  "Fulham":{
    code: "gb-eng",
    latitude: 51.474937,
    longitude: -.221958,
  },
  "Liverpool":{
    code: "gb-eng",
    latitude: 53.430622,
    longitude: -2.960919,
  },
  "Luton Town":{
    code: "gb-eng",
    latitude: 51.884167,
    longitude: -.431667,
  },
  "Manchester City":{
    code: "gb-eng",
    latitude: 53.483176,
    longitude: -2.20038,
  },
  "Manchester United":{
    code: "gb-eng",
    latitude: 53.46315,
    longitude: -2.291444,
  },
  "Newcastle United":{
    code: "gb-eng",
    latitude: 54.975554,
    longitude: -1.62164,
  },
  "Nottingham Forest":{
    code: "gb-eng",
    latitude: 52.94,
    longitude: 1.132778,
  },
  "Sheffield United":{
    code: "gb-eng",
    latitude: 53.370278,
    longitude: -1.470833,
  },
  "Tottenham Hotspur":{
    code: "gb-eng",
    latitude: 51.604444,
    longitude: -.066389,
  },
  "West Ham United":{
    code: "gb-eng",
    latitude: 51.538736,
    longitude: -.016552,
  },
  "Wolverhampton Wanderers":{
    code: "gb-eng",
    latitude: 52.590254,
    longitude: -2.130451,
  },
  "Olympique Marseille": {
    code: "fr",
    latitude: 43.269722,
    longitude: 5.395833,
  },
  "Nantes": {
    code: "fr",
    latitude: 47.255631,
    longitude: -1.525375,
  },
  "Olympique Lyonnais": {
    code: "fr",
    latitude: 45.75322,
    longitude: 4.97318,
  },
  "Brest": {
    code: "fr",
    latitude: 48.402932,
    longitude: -4.461694,
  },
  "Lens": {
    code: "fr",
    latitude: 50.432778,
    longitude: 2.815,
  },
  "Nice": {
    code: "fr",
    latitude: 43.705139,
    longitude: 7.192583,
  },

	"Montpellier": {
		code: "fr",
		latitude: 43.622222,
		longitude: 3.811944,
	},
	"Paris Saint-Germain": {
		code: "fr",
		latitude: 48.841389,
		longitude: 2.253056,
	},
  "FC Metz": {
		code: "fr", 
		latitude: 49.109813,
		longitude: 6.159511,
	},
	"Rennes": {
		code: "fr",
		latitude: 48.107458,
		longitude: -1.712839,
	},
	"Strasbourg": {
		code: "fr",
		latitude: 48.560083,
		longitude: 7.755,
	},
	"Lille": {
		code: "fr",
		latitude: 50.631111,
		longitude: 3.1375,
	},
	"Reims": {
		code: "fr",
		latitude: 49.246389,
		longitude: 4.025,
	},
	"Monaco": {
		code: "fr",
		latitude: 43.727606,
		longitude: 7.415614,
	},
	"Clermont": {
		code: "fr",
		latitude: 45.81582,
		longitude: 3.121651,
	},
	"Lorient": {
		code: "fr",
		latitude: 47.748747,
		longitude: -3.369367,
	},
  "Toulouse":{
  code: "fr",
  latitude: 43.583056,
  longitude:1.434167,
  },
  "Le Havre":{
  code: "fr",
  latitude: 49.498889,
  longitude:0.169722,
  },
  "Roma": {
		code: "it",
		latitude: 41.933886,
		longitude: 12.454786,
	},
	"Lazio": {
		code: "it",
		latitude: 41.933886,
		longitude: 12.454786,
	},
	"Fiorentina": {
		code: "it",
		latitude: 43.780822,
		longitude: 11.282258,
	},
	"Milan": {
		code: "it",
		latitude: 45.478025,
		longitude: 9.124206,
	},
	"Udinese": {
		code: "it",
		latitude: 46.081603,
		longitude: 13.200136,
	},
	"Empoli": {
		code: "it",
		latitude: 43.726389,
		longitude: 10.955,
	},
	"Napoli": {
		code: "it",
		latitude: 40.827967,
		longitude: 14.193008,
	},
	"Torino": {
		code: "it",
		latitude: 45.04175,
		longitude: 7.650014,
	},
	"Juventus": {
		code: "it",
		latitude: 45.109444,
		longitude: 7.641111,
	},
	"Atalanta": {
		code: "it",
		latitude: 45.708889,
		longitude: 9.680833,
	},
	"Hellas Verona": {
		code: "it",
		latitude: 45.435356,
		longitude: 10.968647,
	},
	"Monza": {
		code: "it",
		latitude: 45.582779,
		longitude: 9.307778,
	},
	"Sassuolo": {
		code: "it",
		latitude: 44.714722,
		longitude: 10.649722,
	},
	"Inter Milan": {
		code: "it",
		latitude: 45.478025,
		longitude: 9.124206,
	},
	"Salernitana": {
		code: "it",
		latitude: 40.645,
		longitude: 14.823889,
	},
	"Lecce": {
		code: "it",
		latitude: 40.365278,
		longitude: 18.208889,
	},
	"Bologna": {
		code: "it",
		latitude: 44.492314,
		longitude: 11.309667,
	},
	"Frosinone": {
		code: "it",
		latitude: 41.63426,
		longitude: 13.321866,
	},
"Genoa CFC": {
		code: "it",
		latitude: 44.409856,
		longitude: 8.951452,
	},
"Cagliari": {
		code: "it",
		latitude: 39.1999,
		longitude: 9.1376,
	},
  "Schalke 04": {
    code: "de",
    latitude: 51.553673,
    longitude: 7.07057
  },
"Borussia Dortmund": {
    code: "de",
    latitude: 51.492493,
    longitude: 7.451885
  },
  "Werder Bremen": {
    code: "de",
    latitude: 53.066394,
    longitude: 8.837628
  },
  "Augsburg": {
    code: "de",
    latitude: 48.322971,
    longitude: 10.885949
  },
  "RB Leipzig": {
    code: "de",
    latitude: 51.345833,
    longitude: 12.348056
  },
  "Eintracht Frankfurt": {
    code: "de",
    latitude: 50.068779,
    longitude: 8.64629
  },
  "Bayern Munchen": {
    code: "de",
    latitude: 48.218777,
    longitude: 11.624748
  },
  "Wolfsburg": {
    code: "de",
    latitude: 52.431561,
    longitude: 10.803541
  },
  "Borussia Monchengladbach": {
    code: "de",
    latitude: 51.175213,
    longitude: 6.383958
  },
  "Mainz 05": {
    code: "de",
    latitude: 50.001053,
    longitude: 8.245478
  },
  "VfL Bochum": {
    code: "de",
    latitude: 51.48988,
    longitude: 7.236542
  },
  "Union Berlin": {
    code: "de",
    latitude: 52.457687,
    longitude: 13.567712
  },
  "TSG 1899 Hoffenheim": {
    code: "de",
    latitude: 49.23906,
    longitude: 8.88775
  },
  "Hertha BSC": {
    code: "de",
    latitude: 52.514477,
    longitude: 13.239358
  },
  "VfB Stuttgart": {
    code: "de",
    latitude: 48.791527,
    longitude: 9.230742
  },
  "Köln": {
    code: "de",
    latitude: 50.933445,
    longitude: 6.876095
  },
  "Bayer 04 Leverkusen": {
    code: "de",
    latitude: 51.037704,
    longitude: 7.002651
  },
 "Heidenheim": {
    code: "de",
    latitude: 48.668470,
    longitude: 10.139274
  },
"Darmstadt 98": {
    code: "de",
    latitude: 49.857778,
    longitude: 8.672222
  },
  "Freiburg": {
    code: "de",
    latitude: 48.0216, 
    longitude: 7.8297
  },
  "AZ Alkmaar": {
    code: "nl",
    latitude: 52.612778,
    longitude: 4.742222
  },
  "Twente": {
    code: "nl",
    latitude: 52.236667,
    longitude: 6.8375
  },
  "Go Ahead Eagles": {
    code: "nl",
    latitude: 52.260416,
    longitude: 6.172578
  },
  "FC Utrecht": {
    code: "nl",
    latitude: 52.078333,
    longitude: 5.145833
  },
  "Sparta Rotterdam": {
    code: "nl",
    latitude: 51.919485,
    longitude: 4.433619
  },
  "SC Heerenveen": {
    code: "nl",
    latitude: 52.958611,
    longitude: 5.936111
  },
  "Fortuna Sittard": {
    code: "nl",
    latitude: 50.992069,
    longitude: 5.843675
  },
  "Excelsior Rotterdam": {
    code: "nl",
    latitude: 51.916944,
    longitude: 4.520556
  },
  "PSV Eindhoven": {
    code: "nl",
    latitude: 51.441781,
    longitude: 5.467442
  },
  "Ajax": {
    code: "nl",
    latitude: 52.314167,
    longitude: 4.941944
  },
  "Feyenoord": {
    code: "nl",
    latitude: 51.893894,
    longitude: 4.523253
  },
  "Sporting CP": {
    code: "pt",
    latitude: 38.761246,
    longitude: -9.160833
    },
    "SL Benfica": {
    code: "pt",
    latitude: 38.75267,
    longitude: -9.184697
    },
    "FC Porto": {
    code: "pt",
    latitude: 41.161758,
    longitude: -8.583933
    },
    "Vitória Guimarães SC": {
    code: "pt",
    latitude: 41.445885,
    longitude: -8.301164
    },
    "SC Braga": {
    code: "pt",
    latitude: 41.562565,
    longitude: -8.430133
    },
    "Boavista FC": {
    code: "pt",
    latitude: 41.162278,
    longitude: -8.642753
    },
    "GD Chaves": {
    code: "pt",
    latitude: 41.750552,
    longitude: -7.464952
    },
    "GD Estoril Praia": {
    code: "pt",
    latitude: 38.715852,
    longitude: -9.406409
    },
    "Portimonense SC": {
    code: "pt",
    latitude: 37.135692,
    longitude: -8.539892
    },
    "FC Famalicão": {
    code: "pt",
    latitude: 41.401278,
    longitude: -8.522422
    },
    "FC Arouca": {
    code: "pt",
    latitude: 40.93294,
    longitude: -8.250379
    },
    "Rio Ave FC": {
    code: "pt",
    latitude: 41.362755,
    longitude: -8.740186
    },
    "Casa Pia AC": {
    code: "pt",
    latitude: 38.7370043,
    longitude: -9.2037186
    },
    "SC Farense": {
    code: "pt",
    latitude: 37.022778,
    longitude: -7.928611
    },
    "CF Estrela Amadora SAD": {
    code: "pt",
    latitude: 38.75204,
    longitude: -9.22795
    },
    "FC Vizela": {
    code: "pt",
    latitude: 41.38876,
    longitude: -8.30732
    },
    "Celta de Vigo": {
      code: "es",
      latitude: 42.211852, 
      longitude: -8.739721
    },
    "Getafe": {
      code: "es",
      latitude: 40.325729,
      longitude: -3.714964
    },
    "Valencia": {
      code: "es",
      latitude: 39.474602,
      longitude: -0.358257
    },
    "Girona": {
      code: "es",
      latitude: 41.961389,
      longitude: 2.828611
    },
    "Real Valladolid": {
      code: "es",
      latitude: 41.644444,
      longitude: -4.761111
    },
    "Rayo Vallecano": {
      code: "es",
      latitude: 40.391808,
      longitude: -3.658611
    },
    "Osasuna": {
      code: "es",
      latitude: 42.796677,
      longitude: -1.637141
    },
    "Real Betis": {
      code: "es",
      latitude: 37.356483,
      longitude: -5.981768
    },
    "Espanyol": {
      code: "es",
      latitude: 41.34786,
      longitude: 2.075622
    },
    "Real Sociedad": {
      code: "es",
      latitude: 43.301376,
      longitude: -1.973602
    },
    "UD Almeria": {
      code: "es",
      latitude: 36.840039,
      longitude: -2.435457
    },
    "RCD Mallorca": {
      code: "es",
      latitude: 39.58994,
      longitude: 2.630078
    },
    "Sevilla": {
      code: "es",
      latitude: 37.384046,
      longitude: -5.970592
    },
    "Elche": {
      code: "es",
      latitude: 38.2642,
      longitude: -0.686
    },
    "Real Madrid": {
      code: "es",
      latitude: 40.453068,
      longitude: -3.688354
    },
    "Villarreal CF": {
      code: "es",
      latitude: 39.944119,
      longitude: -0.103474
    },
    "Cádiz CF": {
      code: "es",
      latitude: 36.502547,
      longitude: -6.272958
    },
    "Atlético de Madrid": {
      code: "es",
      latitude: 40.436111,
      longitude: -3.599444
    },
    "Athletic Bilbao": {
      code: "es",
      latitude: 43.263476,
      longitude: -2.94815
    },
    "UD Las Palmas": {
      code: "es",
      latitude: 28.100278,
      longitude: -15.456667
    },
  "Deportivo Alavés": {
      code: "es",
      latitude: 42.837222,
      longitude: -2.688056
    },
    "FC Barcelona": {
      code: "es",
      latitude: 41.38089, 
      longitude: 2.122813
    },
  "Granada CF": {
      code: "es",
      latitude: 37.153056,
      longitude: -3.595833
    },
    "Celtic": {
      code: "gb-sct",
      latitude: 55.849711,
      longitude: -4.205589
    },
    "Rangers": {
      code: "gb-sct",
      latitude: 55.853206,
      longitude: -4.309256
    },
    "Hibernian": {
      code: "gb-sct",
      latitude: 55.961667,
      longitude: -3.165556
    },
    "Motherwell": {
      code: "gb-sct",
      latitude: 55.779947,
      longitude: -3.980078
    },
    "Hearts": {
      code: "gb-sct",
      latitude: 55.939167,
      longitude: -3.232222
    },
    "Young Boys": {
      code: "ch",
      latitude: 46.963167,
      longitude: 7.464833
    },
    "Basel": {
      code: "ch",
      latitude: 47.541575,
      longitude: 7.620181
    },
    "FC Sion": {
      code: "ch",
      latitude: 46.233333,
      longitude: 7.376389
    },
    "Servette": {
      code: "ch",
      latitude: 46.177847,
      longitude: 6.127431
    },
    "Juventude": {
      code: "br",
      latitude: -29.162,
      longitude: -51.176
    },
    "Corinthians": {
      code: "br",
      latitude: -23.545531,
      longitude: -46.473372
    },
    "Flamengo": {
      code: "br",
      latitude: -22.912172,
      longitude: -43.23021
    },
    "Fluminense": {
      code: "br",
      latitude: -22.912172,
      longitude: -43.23021
    },
    "Internacional": {
      code: "br",
      latitude: -30.065417,
      longitude: -51.235891
    },
    "Botafogo": {
      code: "br",
      latitude: -22.893172,
      longitude: -43.292269
    },
    "Palmeiras": {
      code: "br",
      latitude: -23.527556,
      longitude: -46.678417
    },
    "Atlético Mineiro": {
      code: "br",
      latitude: -19.908333,
      longitude: -43.917778
    },
    "Athletico PR": {
      code: "br",
      latitude: -25.448186,
      longitude: -49.276866
    },
    "São Paulo": {
      code: "br",
      latitude: -23.600113,
      longitude: -46.720093
    },
    "Fortaleza": {
      code: "br",
      latitude: -3.807487,
      longitude: -38.522542
    },
    "Santos": {
      code: "br",
      latitude: -23.951094,
      longitude: -46.338894
    },
    "Cuiabá": {
      code: "br",
      latitude: -15.606944,
      longitude: -56.105
    },
    "Coritiba": {
      code: "br",
      latitude: -25.448186,
      longitude: -49.276866
    },
    "América Mineiro": {
      code: "br",
      latitude: -19.908333,
      longitude: -43.917778
    },
    "Avaí": {
      code: "br",
      latitude: -27.666445,
      longitude: -48.531654
    },
    "Red Bull Bragantino": {
      code: "br",
      latitude: -22.965414,
      longitude: -46.536936
    },
    "Atlético GO": {
      code: "br",
      latitude: -16.698889,
      longitude: -49.233889
    },
    "Goiás": {
      code: "br",
      latitude: -16.698889,
      longitude: -49.233889
    },
    "Ceará": {
      code: "br",
      latitude: -3.807487,
      longitude: -38.522542
    },
    "ABC": {
      code: "br",
      latitude: -5.889167,
      longitude: -35.18
    },
    "Amazonas FC": {
      code: "br",
      latitude: -3.083056,
      longitude: -60.028056
    },
    "Bahia": {
      code: "br",
      latitude: -12.978611,
      longitude: -38.504167
    },
    "Botafogo-SP": {
      code: "br",
      latitude: -21.202503,
      longitude: -47.78915
    },
    "Brusque": {
      code: "br",
      latitude: -27.1014,
      longitude: -48.9161
    },
    "Chapecoense": {
      code: "br",
      latitude: -27.104139,
      longitude: -52.607
    },
    "CRB": {
      code: "br",
      latitude: -9.670556,
      longitude: -35.759167
    },
    "Cruzeiro": {
      code: "br",
      latitude: -19.865833,
      longitude: -43.970833
    },
    "Grêmio Novorizontino": {
      code: "br",
      latitude: -21.466881,
      longitude: -49.232135
    },
    "Grêmio": {
      code: "br",
      latitude: -29.973444,
      longitude: -51.194403
    },
    "Guarani": {
        code: "br",
        latitude: -22.909722,
        longitude: -47.043611
      },
    "Ituano": {
        code: "br",
        latitude: -23.28081,
        longitude: -47.287675
      },
    "Londrina": {
        code: "br",
        latitude: -23.282225,
        longitude: -51.165011
      },
    "Mirassol": {
        code: "br",
        latitude: -20.822392,
        longitude: -49.506422
      },
    "Operário Ferroviário": {
        code: "br",
        latitude: -25.116169,
        longitude: -50.156875
      },
    "Paysandu": {
        code: "br",
        latitude: -1.443889,
        longitude: -48.462778
      },
    "Ponte Preta": {
        code: "br",
        latitude: -22.913611,
        longitude: -47.051389
      },
    "Remo": {
        code: "br",
        latitude: -1.445,
        longitude: -48.465833
      },
    "Sampaio Corrêa": {
        code: "br",
        latitude: -2.548611,
        longitude: -44.259722
      },
    "Sport Recife": {
        code: "br",
        latitude: -8.062778,
        longitude: -34.902778
      },
    "Tombense": {
        code: "br",
        latitude: -20.9075,
        longitude: -42.030278
      },
    "Vasco da Gama": {
        code: "br",
        latitude: -22.890917,
        longitude: -43.228253
      },
    "Vila Nova": {
        code: "br",
        latitude: 16.673558,
        longitude: -49.237725
      }, 
    "Vitória": {
        code: "br",
        latitude: -12.919167,
        longitude: -38.427222
      },
    "Paraná Clube": {
      code: "br",
      latitude: -25.439444,
      longitude: -49.255833
    },
    "Fenerbahçe": {
      code: "tr",
      latitude: 40.9877,
      longitude: 29.037
    },
    "Galatasaray": {
      code: "tr",
      latitude: 41.1301, 
      longitude: 28.96
    },
    "Besiktas": {
      code: "tr",
      latitude: 41.0391, 
      longitude: 28.9946
    },
    "Trabzonspor": {
      code: "tr",
      latitude: 40.999167, 
      longitude: 39.645833
    },
    "Argentinos Juniors": {
      code: "ar",
      latitude: -34.606028, 
      longitude: -58.472583
    },
    "Boca Juniors": {
      code: "ar",
      latitude: -34.6358, 
      longitude: -58.3647
    },
    "Cerro Porteno": {
      code: "py",
      latitude: -25.3, 
      longitude: -57.6375
    },
    "Estudiantes": {
      code: "ar",
      latitude: -34.911925, 
      longitude: -57.938794
    },
    "Gimnasia y Esgrima": {
      code: "ar",
      latitude: -34.910975, 
      longitude: -57.932592
    },
    "Huracán": {
      code: "ar",
      latitude: -34.6435, 
      longitude: -58.3965
    },
    "Independiente": {
      code: "ar",
      latitude: -34.6703, 
      longitude: -58.371
    },
    "Newell's Old Boys": {
      code: "ar",
      latitude: -32.956056, 
      longitude: -60.661444
    },
    "Olimpia": {
      code: "py",
      latitude: -25.2914,
      longitude: -57.6085
    },
    "Racing": {
      code: "ar",
      latitude: -34.6675, 
      longitude: -58.368611
    },
    "River Plate": {
      code: "ar",
      latitude: -34.545278, 
      longitude: -58.449722
    },
    "Rosario Central": {
      code: "ar",
      latitude: -32.913997, 
      longitude: -60.674567
    },
    "San Lorenzo": {
      code: "ar",
      latitude: -34.652064, 
      longitude: -58.440119
    },
    "Vélez Sarsfield": {
      code: "ar",
      latitude: -34.635375, 
      longitude: -58.520711
    },
    "Peñarol": {
      code: "uy",
      latitude: -34.796917, 
      longitude: -56.067167 
    },
    "Nacional": {
      code: "uy",
      latitude: -34.884373,  
      longitude: -56.1588
    },
    "Colo-Colo": {
      code: "cl", 
      latitude: -33.506611, 
      longitude: -70.605944
    },
    "Universidad de Chile": {
      code: "cl",
      latitude: -33.4645, 
      longitude: -70.6105
    },
    "FC Midtjylland": {
    code: "dk",
    latitude: 56.11691,
    longitude: 8.951817
    },
    "Bröndby IF": {
    code: "dk",
    latitude: 55.648912,
    longitude: 12.418216
    },
    "Viborg FF": {
    code: "dk",
    latitude: 56.4561,
    longitude: 9.401849
    },
    "Odense Boldklub": {
    code: "dk",
    latitude: 55.397831,
    longitude: 10.350139
    },
    "Randers FC": {
    code: "dk",
    latitude: 56.465955,
    longitude: 10.010346
    },
    "FC Nordsjaelland": {
    code: "dk",
    latitude: 55.816125,
    longitude: 12.352792
    },
    "Lyngby BK": {
    code: "dk",
    latitude: 55.781454,
    longitude: 12.505519
    },
    "Aarhus GF": {
    code: "dk",
    latitude: 56.13203,
    longitude: 10.196503
    },
    "FC Copenhagen": {
    code: "dk",
    latitude: 55.702712,
    longitude: 12.572275
    },
    "Silkeborg IF": {
    code: "dk",
    latitude: 56.184081,
    longitude: 9.575425
    },
    "Vejle Boldklub": {
    code: "dk",
    latitude: 55.71372,
    longitude: 9.5562
    },
    "Hvidovre IF": {
    code: "dk",
    latitude: 55.631922,
    longitude: 12.482878
    },
    "Sint-Truidense VV": {
      code: "be",
      latitude: 50.813474,
      longitude: 5.166256
    },
    "KVC Westerlo": {
      code: "be",
      latitude: 51.094817,
      longitude: 4.928806
    },
    "Standard Liège": {
      code: "be",
      latitude: 50.609908,
      longitude: 5.543344
    },
    "RSC Charleroi": {
      code: "be",
      latitude: 50.414644,
      longitude: 4.453765
    },
    "KV Kortrijk": {
      code: "be",
      latitude: 50.830432,
      longitude: 3.248906
    },
    "Oud-Heverlee Leuven": {
      code: "be",
      latitude: 50.868333,
      longitude: 4.694167
    },
    "KAS Eupen": {
      code: "be",
      latitude: 50.626389,
      longitude: 6.045833
    },
    "Cercle Brugge": {
      code: "be",
      latitude: 51.193272,
      longitude: 3.180583
    },
    "Genk": {
      code: "be",
      latitude: 51.005,
      longitude: 5.533333
    },
    "KV Mechelen": {
      code: "be",
      latitude: 51.037184,
      longitude: 4.486397
    },
   "Club Brugge KV": {
      code: "be",
      latitude: 51.1932,
      longitude: 3.1812
    },
  "KAA Gent": {
      code: "be",
      latitude: 51.016079,
      longitude: 3.733786
    },
    "RSC Anderlecht": {
      code: "be",
      latitude: 50.834167, 
      longitude: 4.298333
    },
    "Royal Antwerp FC": {
      code: "be",
      latitude: 51.2325,
      longitude: 4.472222
    },
     "RWD Molenbeek": {
      code: "be",
      latitude: 50.855,
      longitude: 4.311
    },
     "Saint-Gilloise": {
      code: "be",
      latitude: 50.817778,
      longitude: 4.329167
    },
    "Austria Vienna": {
      code: "at",
      latitude: 48.162306,
      longitude: 16.386714
      },
    "Rapid Vienna": {
      code: "at",
      latitude: 48.198056,
      longitude: 16.265278
      },
  "Wolfsberger AC": {
      code: "at",
      latitude: 46.826258,
      longitude: 14.851989
      },
  "LASK": {
      code: "at",
      latitude: 48.23620,
      longitude: 14.228302
      },
  "SC Austria Lustenau": {
      code: "at",
      latitude: 47.421111,
      longitude: 9.652778
      },
  "WSG Tirol": {
      code: "at",
      latitude: 47.256024,
      longitude: 11.411162
      },
  "TSV Hartberg": {
      code: "at",
      latitude: 47.28091,
      longitude: 15.977381
      },
  "Red Bull Salzburg": {
      code: "at",
      latitude: 47.743302,
      longitude: 13.04753
      },
  "SK Sturm Graz": {
      code: "at",
      latitude: 47.046111,
      longitude: 15.454444
      },
  "FC Blau-Weiss Linz": {
      code: "at",
      latitude: 48.3163,
      longitude: 14.2987
      },
      "PAOK": {
        code: "gr",
        latitude: 40.613839,
        longitude: 22.972422
        },
      "OFI Crete FC": {
        code: "gr",
        latitude: 35.338056,
        longitude: 25.114722
        },
    "Panetolikos": {
        code: "gr",
        latitude: 38.629967,
        longitude: 21.411747
        },
    "PAS Giannina": {
        code: "gr",
        latitude: 39.667647,
        longitude: 20.848669
        },
    "AEK Athens": {
        code: "gr",
        latitude: 38.036092,
        longitude: 23.787633
        },
    "Asteras Tripolis": {
        code: "gr",
        latitude: 37.521989,
        longitude:22.378164
        },   
    "Atromitos Athens": {
        code: "gr",
        latitude: 38.005074,
        longitude:23.689891
        },         
     "Volos NFC": {
        code: "gr",
        latitude: 39.387511,
        longitude:22.931063
        },          
     "Olympiakos": {
        code: "gr",
        latitude: 37.946447,
        longitude:23.664317
        },          
      "Panathinaikos": {
        code: "gr",
        latitude: 38.036092,
        longitude:23.787633
        },      
      "Aris Thessaloniki": {
        code: "gr",
        latitude: 40.6,
        longitude:22.9695
        },
    "Panserraikos": {
        code: "gr",
        latitude: 41.0847,
        longitude:23.5466
        },        
    "PAS Lamia 1964": {
        code: "gr",
        latitude: 38.91,
        longitude:22.431111
        },
        "Lech Poznan": {
          code: "pl",
          latitude: 52.397222,
          longitude: 16.858056
          },
       "Legia Warszawa": {
          code: "pl",
          latitude: 52.220461,
          longitude: 21.040628
          },
       "Pogon Szczecin": {
          code: "pl",
          latitude: 53.436576,
          longitude: 14.518658
          },  
       "Jagiellonia Bialystok": {
          code: "pl",
          latitude: 53.105556,
          longitude: 23.148889
          },  
       "Cracovia": {
          code: "pl",
          latitude: 50.057969,
          longitude: 19.920182
          },
      "Zaglebie Lubin": {
          code: "pl",
          latitude: 51.414204,
          longitude: 16.197925
          },
      "Piast Gliwice": {
          code: "pl",
          latitude: 50.30242,
          longitude: 18.69369
          },      
       "Radomiak Radom": {
          code: "pl",
          latitude: 51.4101701,
          longitude: 21.1656655
          },
      "Korona Kielce": {
          code: "pl",
          latitude: 50.861389,
          longitude: 20.624722
          },
      "Rakow Czestochowa": {
          code: "pl",
          latitude: 50.7764,
          longitude: 19.15777
          },
      "Gornik Zabrze": {
          code: "pl",
          latitude: 50.296317,
          longitude: 18.768564
          },
      "Stal Mielec": {
          code: "pl",
          latitude: 50.298611,
          longitude: 21.435833
          },
      "Widzew Lodz": {
          code: "pl",
          latitude: 51.765,
          longitude: 19.511667
          },  
      "Ruch Chorzow": {
          code: "pl",
          latitude: 50.2881,
          longitude: 18.9736
          }, 
      "Warta Poznan": {
          code: "pl",
          latitude: 52.393611,
          longitude: 16.931111
          },
      "Slask Wroclaw": {
          code: "pl",
          latitude: 51.143056,
          longitude: 16.942222
          },
          "Dynamo Kyiv": {
            code: "ua",
            latitude: 50.433439,
            longitude: 30.522114
            },
        "Shakhtar Donetsk": {
            code: "ua",
            latitude: 48.020833,
            longitude: 37.809722
            },
        "Dnipro-1": {
            code: "ua",
            latitude: 48.460333,
            longitude: 35.032472
            },
        "Metalist 1925 Kharkiv": {
            code: "ua",
            latitude: 49.980858,
            longitude: 36.261703
            },
            "Atlanta United FC":{
              code: "us",
              latitude: 33.75555556,
              longitude: -84.4,
            },
          "Austin FC":{
              code: "us",
              latitude: 30.3877,
              longitude: -97.7195,
            },  
           "CF Montréal":{
              code: "ca",
              latitude: 45.56305556,
              longitude: -73.5525,
            }, 
              "Charlotte FC":{
              code: "us",
              latitude: 35.22583333,
              longitude: -80.85277778,
            }, 
             "Chicago Fire":{
              code: "us",
              latitude: 41.8623,
              longitude: -87.6167,
            },
            "Colorado Rapids":{
              code: "us",
              latitude: 39.80555556,
              longitude: -104.89194444,
            },
            "Columbus Crew":{
              code: "us",
              latitude: 39.96846111,
              longitude: -83.01708889,
            },
            "D.C. United":{
              code: "us",
              latitude: 38.868411,
              longitude: -77.012869,
            },
            "FC Cincinnati":{
              code: "us",
              latitude: 39.11138889,
              longitude: -84.52222222,
            },
           "FC Dallas":{
              code: "us",
              latitude: 33.15444444,
              longitude: -96.83527778,
            },
            "Houston Dynamo":{
              code: "us",
             latitude: 29.7522,
             longitude: -95.3524,
            },
           "Inter Miami":{
              code: "us",
             latitude: 26.19305556,
             longitude: -80.16111111,
            },
           "LA Galaxy":{
              code: "us",
             latitude: 33.864,
             longitude: -118.261,
            },
             "Los Angeles FC":{
              code: "us",
              latitude: 34.013,
              longitude: -118.285,
            },
               "Minnesota United":{
              code: "us",
              latitude: 44.95277778,
              longitude: -93.165,
            },
             "Nashville SC":{
              code: "us",
              latitude: 36.13027778,
              longitude: -86.76555556,
            },
            "New England Revolution":{
              code: "us",
              latitude: 42.091,
              longitude: -71.264,
            },
            "New York City FC":{
              code: "us",
              latitude: 40.82916667,
              longitude: -73.92638889,
            },
            "New York Red Bulls":{
              code: "us",
              latitude: 40.73666667,
              longitude: -74.15027778,
            },
            "Orlando City SC":{
              code: "us",
              latitude: 28.5411,
              longitude: -81.3893,
            },
            "Philadelphia Union":{
              code: "us",
              latitude: 39.83222222,
              longitude: -75.37888889,
            },
            "Portland Timbers":{
              code: "us",
              latitude: 45.52138889,
              longitude: -122.69166667,
            },
            "Real Salt Lake":{
              code: "us",
              latitude: 40.5829,
              longitude: -111.8934,
            },
            "San Jose Earthquakes":{
              code: "us",
              latitude: 37.35138889,
              longitude: -121.925,
            },
            "Seattle Sounders":{
              code: "us",
              latitude: 47.5952,
              longitude: -122.3316,
            },
            "Sporting Kansas City":{
              code: "us",
              latitude: 39.1214,
              longitude: -94.821,
            },
            "St. Louis City SC":{
              code: "us",
              latitude: 38.63138889,
              longitude: -90.21027778,
            },
            "Toronto FC":{
              code: "ca",
              latitude: 43.63277778,
              longitude: -79.41861111,
            },
            "Vancouver Whitecaps":{
              code: "ca",
              latitude: 49.27666667,
              longitude: -123.11194444,
            },
            
           
     
};

export default countryData;
