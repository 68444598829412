import { Twemoji } from '@teuteuf/react-emoji-render';
import styled from 'styled-components';

import { useDistanceInUserUnit } from '../hooks/useDistanceInUserUnit';
import { Guess } from '../hooks/useGuessHistory';
import { getDirectionEmoji } from '../utils/geography';

const GuessLine = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(30px, 2.5rem));
  grid-gap:2px;
  margin: 0px 2px 2px 2px;
  padding: 0 1em;
`;
const GuessTry = styled.div`
font-weight: 600;

margin:.25em 0;


`;
const TimeGuess = styled.div`
  display: flex;
 
  position: relative;
  background-color: #94a3b8;
  border-radius: 3px;
  grid-column: 1 / span 5;
  margin-right: 2px;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
`;
const Country = styled.div`
  display: flex;
  position: relative;
  
  background-color: #94a3b8;
  border-radius: 3px;
  grid-column: 1 / span 2;
  margin-right: 2px;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
`;

const DistanceBox = styled.div`
  display: flex;
  position: relative;
  
  background-color: #94a3b8;
  border-radius: 3px;
  grid-column: 3 / span 3;
  font-weight: bold;
  margin-right: 2px;
  align-items: center;
  justify-content: center;
`;

const ArrowBox = styled.div`
  display: flex;
  padding: 0.25rem;
  position: relative;
  background-color: #94a3b8;
  border-radius: 3px;
  grid-column: 6 / span 1;
  align-items: center;
  justify-content: center;
`;

export const GuessList: React.FC<{
  guesses: Guess[];
  answers: string;
}> = ({ guesses, answers }) => {
  const { formatDistance } = useDistanceInUserUnit();

  return (
    <>
      {guesses.map((guess, index) => (
        <div>
        <div style={{ color: '#fff' }}>
        <GuessTry>{index +1} - {guess.name}</GuessTry>
        </div>
        <GuessLine key={index}>
          
          {/* <TimeGuess style={{ backgroundColor: guess.distance === 0 ? '#59C174' : '#94a3b8' }}>{guess.name}</TimeGuess> */}
          <Country style={{ backgroundColor: guess.code === answers ? '#59C174' : '#94a3b8' }}>
            <img className='bandeira' src={require(`../../public/images/bandeiras/${guess.code}.svg`)} alt="Flag" />
          </Country>
          <DistanceBox style={{ backgroundColor: guess.distance === 0 ? '#59C174' : '#94a3b8' }} >{formatDistance(guess.distance)} </DistanceBox>
          <ArrowBox style={{ backgroundColor: guess.distance === 0 ? '#59C174' : '#94a3b8' }}> 
            <Twemoji
              text={getDirectionEmoji(guess)}
              options={{ baseUrl: '//cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/' }}
            />
          </ArrowBox>
        </GuessLine>
        </div>
      ))}
    </>
  );
};
